* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Montserrat", "sans-serif";
    min-width: calc(256px + 500px);
  }
  
  