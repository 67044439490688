.upload-button {
  border: 2px #c3c3c3 dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  padding: 16px;
  margin: 16px 0px;
}

.upload-button:hover,
.drag-drop {
  background-color: rgba(75, 117, 172, 0.1);
  border-color: rgba(52, 73, 121, 1);
}

.error {
  background-color: rgba(211, 47, 47, 0.04) !important;
  border-color: rgba(204, 0, 0, 1) !important;
}

.error > div > .upload-file-icon, .error > .upload-caption {
  color: rgba(204, 0, 0, 1);
}

.hidden {
  display: none;
}
